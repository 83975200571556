import Link from 'next/link';
import styled from 'styled-components';

import Footer from '@components/Footer';
import Navigation from '@components/Navigation';
import { brand } from '@constants/colors';
import { books, login } from '@constants/routes';
import { space } from '@constants/spaces';
import Button from '@elements/Button';
import Title from '@elements/Title';
import { useGetAccountInfoQuery } from '@graphql/generated/graphql';

const Component = styled.div`
  width: 100%;
  height: calc(100% - 64px - 36px); // navigation
  display: flex;
  padding: 5%;
  flex-direction: column;
`;

const Body = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: ${space * 2}px;
  margin-top: ${space * 4}px;
`;

const StyledLink = styled.a`
  color: ${brand};
  font-weight: 500;

  &:hover {
    color: ${brand};
  }
`;

const Custom404 = () => {
  const { data } = useGetAccountInfoQuery();

  return (
    <>
      <Navigation />
      <Component>
        <Body>
          <Title as="h1" size="nm">
            Maak je keuze
          </Title>
          <Actions>
            <Link href={data?.account.firstName ? books : login}>
              <a aria-label="Mijn Boeken">
                <Button set="primary">Naar mijn boek</Button>
              </a>
            </Link>
            <StyledLink href="/">
              <a>Naar home</a>
            </StyledLink>
          </Actions>
        </Body>
      </Component>
      <Footer />
    </>
  );
};

export default Custom404;
